@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 100;
    src: url('./SpaceGrotesk-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: normal;
    src: url('./SpaceGrotesk-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    src: url('./SpaceGrotesk-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
    src: url('./SpaceGrotesk-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: bold;
    src: url('./SpaceGrotesk-Bold.ttf') format('truetype');
}