/* Depth 2, Frame 1 */

/* Auto layout */
.inside-layout-0 {
    flex: none;
    order: 0;
    flex-grow: 0;
}

.inside-layout-1 {
    flex: none;
    order: 1;
    flex-grow: 0;
}

.inside-layout-2 {
    flex: none;
    order: 4;
    flex-grow: 0;
}

.main-body-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 20px 160px; */
    padding: 0 12.4%;
    /* width: 1280px; */
    /* height: 928px; */
    width: 100%;
    height: 100%;
    overflow: scroll;
}

/* Depth 3, Frame 0 */

/* Auto layout */
.main-body-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0px; */
    padding: 0;

    /* width: 960px; */
    /* height: 888px; */
    width: 100%;
    height: 95.7%;
}


/* Depth 4, Frame 0 */
.main-body-title-container {
    /* width: 960px; */
    width: 100%;
    margin-bottom: 2%;
    /* height: 12%; */
    /* padding: 10%; */
}

/* Depth 5, Frame 0 */

/* Auto layout */
.main-body-title-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0px; */
    padding: 0;
    gap: 12px;
    /* width: 288px; */
    /* height: 81px; */

    margin: 2% 2% 0;
}

/* Depth 6, Frame 0 */

/* Auto layout */
.main-body-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0px; */
    padding: 0;
    /* width: 288px; */
}

.main-body-title-text {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 45px;
    /* identical to box height, or 125% */
    letter-spacing: -1.188px;

    color: #1C1C21;
}


/* Depth 6, Frame 1 */

/* Auto layout */
.main-body-subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: 24px;

    align-self: stretch;
}

.main-body-subtitle-layout {
    height: 24px;
}

.main-body-subtitle-text {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #3D404A;
}

/* Depth 4, Frame 1 */

/* Auto layout */
.style-title-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2% 2% 0;

    /* width: 960px; */
    width: 100%;
}


/* Depth 5, Frame 0 */

/* Auto layout */
.style-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    /* height: 22.5px; */
    align-self: stretch;
}

.style-title-text {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 125% */
    letter-spacing: -0.27px;

    color: #1C1C21;
}

/* Depth 4, Frame 4 */
.user-image-container {
    /* width: 960px; */
    /* height: 554px; */
    width: 100%;
    
    padding: 2%;
    /* Inside auto layout */
    flex: none;
    order: 4;
    flex-grow: 0;
}

/* Depth 5, Frame 0 */

/* Auto layout */
.user-image-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    width: 100%;

    border-radius: 12px;
}

.user-image {
    border-radius: 12px;
}

.style-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.style-button {
    /* fixed middle button */
    position: fixed;
    bottom: 5%;
    left: max(50%, 150px);
    transform: translateX(-50%);
    z-index: 10;
    width: 200px;
    height: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    background-color: #617AFA;
}

:hover.style-button {
    background-color: #4F63E6;
}

.style-button-text {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    letter-spacing: -0.27px;

    color: #FAFAFA;
}

.dashed-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 2px dashed #3B3D54;
    border-radius: 12px;
    gap: 30px;
}

.dashed-box-text {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 122% */
    letter-spacing: -0.27px;
}

.dashed-box-btn {
    background-color: #EDF0F2;
    border-radius: 12px;
    padding: 10px 20px;
    
    width: 150px;
    height: 40px;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    letter-spacing: 0.21px;
    color: #1C1C21;
}

.style-list-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding: 0 2%;
}

.style-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    padding: 2% 0;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
}

.style-list-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: scroll;
}

.style-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 5px;
    margin: 0;
    margin-right: 12px;

    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.style-select {
    border-radius: 6px;
    border-width: 2.5px;
}

.style-image {
    border-radius: 5px;
    min-width: 105px;
    width: 105px;
    height: 100px;

    object-fit: cover;

    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    -webkit-user-drag: none;

    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.style-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    height: 32px;
    border-radius: 12px;

    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.style-item-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.style-text {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    align-self: stretch;
}